import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'

import Background from '../components/Background'
import Social from '../components/Social'

const Container = styled.div`
  ${ props => props.theme.wrapper }
  ${ props => props.theme.paddingTop }
  ${ props => props.theme.backgroundTop }
`

const Text = styled(Col)`
  margin-bottom: 6.875rem;

  p {
    margin-bottom: 1rem;
  }
`

const ImpressumPage = ({ data: { datoCmsLegal: { text } } }) => (
  <Background openTop>
    <Container>
      <Row>
        <Col smOffset={2}>
          <h1>Impressum</h1>
        </Col>
      </Row>
      <Row>
        <Text smOffset={2} sm={10} xs={12} dangerouslySetInnerHTML={{ __html: text }}/>
      </Row>
      <Social/>
    </Container>
  </Background>
)

export const query = graphql`
  query ImpressumPage {
    datoCmsLegal(name: {eq: "Impressum"}) {
      text
    }
  }
`

export default ImpressumPage
